<template>
  <div data-testid="navbar-user-component" ref="loginModal">
    <div class="relative flex">
      <div class="flex items-center">
        <div
          class="cursor-pointer z-20 relative flex items-center group"
          :class="{ 'flex-col': isMm }"
          @click="toggle"
        >
          <NuxtImg
            v-if="userStore.hasImage"
            :src="`https://legacy.altinget.dk/images/useravatars/${userStore.user?.avatar}`"
            class="w-6 h-6 md:mr-1 inline"
            width="24"
            height="24"
            :class="[
              { 'opacity-100': isLoginPopupOpen },
              userStore.user?.avatar
                ? 'rounded-full'
                : 'opacity-50 group-hover:opacity-100 transition-opacity duration-100',
            ]"
          />
          <img
            v-else
            :src="ProfileFallback"
            alt="Profile"
            class="w-6 h-6 md:mr-1 inline"
            :class="!userStore.user ? ['md:hidden'] : []"
          />
          <span
            class="md:inline-block list-title-xxxs !font-medium select-none hidden opacity-50 group-hover:opacity-100 transition-opacity duration-100"
            :class="[
              {
                'opacity-100': isLoginPopupOpen,
                'clarity-mask': userStore.user,
              },
            ]"
          >
            <span data-testid="user-logged-in" v-if="userStore.user">{{
              userStore.user.name
            }}</span>
            <span data-testid="nav-login-button" v-else>{{ $t('Login') }}</span>
            <img
              v-if="!userStore.user"
              src="@/assets/icons/icon-action-login.svg?url"
              class="w-4 h-4 ml-2 inline-block"
              alt="Login"
            /><img
              v-if="userStore.user"
              src="@/assets/icons/dropdown.svg?url"
              alt="Dropdown"
              class="w-4 h-4 ml-1 inline opacity-50 group-hover:opacity-100 transition-opacity duration-100"
              :class="[{ 'opacity-100': isLoginPopupOpen }]"
            />
          </span>
        </div>
        <div
          v-if="isLoginPopupOpen"
          data-testid="navbar-login-popup"
          class="flex flex-col top-10 mt-2 right-0 grid-cols-5 z-10 min-w-48 absolute bg-white shadow rounded-lg p-2"
        >
          <CommonLogin
            v-if="!userStore.user"
            ref="login"
            :try-login="true"
            v-model:email="input.email"
            v-model:password="input.password"
            @loggedIn="hide"
          />
          <div v-else data-testid="navbar-mit-altinget">
            <NuxtLink
              v-if="userStore.user.loginType != 'IP'"
              :to="{ name: accountItem.route }"
              @click.native="toggle"
              class="label-s text-gray-500 uppercase px-2 py-3"
            >
              {{ $t(accountItem.label) }}
            </NuxtLink>
            <ul class="list-title-xxxs !font-medium">
              <template v-if="userStore.user.loginType != 'IP'">
                <li
                  v-for="item in menuItems"
                  class="px-2 py-3 rounded hover:bg-gray-100 transition-bg duration-100"
                >
                  <NuxtLink :to="item.route" @click.native="toggle">
                    <CommonFontAwesomeIcon
                      class="text-sm text-gray-700 w-3.5 mr-1.5"
                      :icon="item.icon"
                    />
                    {{ $t(item.label) }}
                  </NuxtLink>
                </li>
                <CommonDivider customMargin="my-2" />
              </template>
              <li
                class="flex items-center cursor-pointer px-2 py-3 rounded hover:bg-gray-100 transition-bg duration-100"
                @click="logout"
              >
                <img
                  src="~/assets/icons/icon-action-logout.svg?url"
                  class="w-4 h-4 mr-2.5 inline-block"
                />
                {{ $t('Logout') }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <NuxtLink
        id="UserCreateProfile"
        :to="
          $config.public.site.identifier === 'altinget_no'
            ? { name: 'pricing' }
            : { name: 'subscription' }
        "
        v-if="!userStore.user"
        class="button-l rounded-full px-4 border h-[36px] bg-blue text-white border-none whitespace-nowrap cursor-pointer items-center transition-colors inline-flex duration-100 select-none shrink-0 justify-center ml-5"
        :class="isMm ? 'hidden lg:flex' : 'md:flex'"
      >
        {{ $t('CreateProfile') }}
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import ProfileFallback from '@/assets/icons/profile.svg?url'
import { InputFieldInjectionKey } from '~/typesManual/InjectKeys'

const { isMm } = useBrand()
const userStore = useUserStore()
const { accountItem, menuItems } = useAccountMenu()

const emit = defineEmits<{
  (e: 'hideMenu'): void
}>()
const route = useRoute()

const loginInputField = ref<HTMLInputElement>()
// Provide a function to Input.vue so we can get the input field reference.
// With this we can focus the email field when opening the modal
provide(InputFieldInjectionKey, (inputField: HTMLInputElement) => {
  loginInputField.value = inputField
})
const isLoginPopupOpen = ref(false)
const input = ref({
  email: '',
  password: '',
})

function toggle() {
  isLoginPopupOpen.value = !isLoginPopupOpen.value
  emit('hideMenu')
  if (isLoginPopupOpen.value && loginInputField?.value) {
    loginInputField.value.focus()
  }
}
function hide() {
  isLoginPopupOpen.value = false
}
async function logout() {
  if (!userStore.user) {
    return
  }

  if (userStore.user.loginType !== 'SSO') {
    await userStore.logout()
    reloadNuxtApp({ path: route.fullPath })
  } else {
    await userStore.logout()
  }
}
const loginModal = ref<HTMLDivElement>()
onClickOutside(loginModal, () => {
  hide()
})
</script>
